/* eslint-disable no-unused-vars */
import React from 'react';
import Header from './header.js';
import Content from './main.js';
import Footer from './footer.js';
import Slide from './slide.js';
import { Route,Routes, HashRouter } from 'react-router-dom';

import './app.scss';
import './responsive.scss';
import './covid.scss';
export default function App() {
    return (
        <>
                {/* <Switch>
                    <Route exact path='/'> */}
                        <Header />  
                        {/* <div class="alert">
  <span>تنبيه !!  </span>
                 بناءً على أمر الدفاع رقم (35)، واعتباراً من تاريخ <hd>1/1/2022</hd>، لن يسمح لأي شخص من الدخول إلى حرم الكلية إلا بحصوله على جرعتي مطعوم. <br/>
* لا يوجد اي استثناءات على التطعيم مثل :الحوامل، المرضعات، الحساسيات، المصابين السابقين (يمكن أخذ الجرعة  بعد شهر من الشفاء)، الحالات المرضية، المطعمين جرعة واحدة فقط، ذوي الاحتياجات الخاصة قيد العلاج. الاستثناءات الخاصة يجب 
أن تكون بتقرير طبي موقّع من قبل وزير الصحة أو من ينوب عنه بكتاب رسمي.<br/>
*لا تنطبق هذه التعليمات على من هو أقل من 18 عاماً.
</div> */}
                        <Slide />
                        <Content />
                        <Footer />
                    {/* </Route>
                </Switch> */}
        </>
    );
}