import React, { useState } from 'react';
import data from '../data/Arabic.json';
import covid19Data from '../data/covid19-arabic.json'
// import covid19English from '../data/covid19-english.json'
export const ModelContext = React.createContext();

function ModelProvider(props) {

    const [headerData, setHeaderData] = useState(data.header);
    const [mainData, setMainData] = useState(data.main);
    const [footerData, setFooterData] = useState(data.footer);
    const [covid19ArabicData, setCovid19ArabicData] = useState(covid19Data.content)
    const [lang, setLang] = useState('English');

    const state = {
        headerData,
        mainData,
        footerData,
        covid19ArabicData,
        lang,
        changeHeaderData: setHeaderData,
        changeMainData: setMainData,
        changeFooterData: setFooterData,
        changeCovid19ArabicData: setCovid19ArabicData,
  
        changeLang: setLang,
    };

    return (
        <ModelContext.Provider value={state}>
            {props.children}
        </ModelContext.Provider>
    );
}

export default ModelProvider;