/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import App from './components/app.js';
import ModelProvider from '../src/components/context.js';

function Main() {
    return (
      <ModelProvider>
        <BrowserRouter>
        <App />
      </BrowserRouter>
      </ModelProvider>
    );
}

const root = document.getElementById('root');
ReactDOM.render(<Main />, root);