/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';
import $ from 'jquery';
import { Link, NavLink } from 'react-router-dom';
import { ModelContext } from './context.js';
import mssIcon from '../assets/facebook-messenger-logo-png-44107.png';

function Header() {
    const context = useContext(ModelContext);
 
    useEffect(() => {
        $('.main-nav-mobile').hide();
        $('.sub-dropdown-content').hide();
        $('.list-dropdown-content').hide();
    });
    useEffect(() => {
        var i = 0;
 
        carousel();
        function carousel() {
            let slide = document.getElementsByClassName('s1');
            let dot = document.querySelector(`#r${i + 1}`);
            if (slide[0] !== undefined) {

                slide[0].style.marginLeft = -i * (100 / 6) + '%';
                dot.style.background = '#ffffff';
                i++;
                if (i === 6) {
                    i = 0;
                }
                setTimeout(carousel, 5000);
            }
        }

        $('.bar').on('click', function (e) {
            i = e.target.id - 1;
            let slide = document.getElementsByClassName('s1');
            slide[0].style.marginLeft = -i * (100 / 6) + '%';
        });



    }, []);
    useEffect(() => {
        var i = 0;
        carouselM();

        function carouselM() {
            let slide = document.getElementsByClassName('sM1');
            let dot = document.querySelector(`#rM${i + 1}`);
            if (slide[0] !== undefined) {
                slide[0].style.marginLeft = -i * (100 / 6) + '%';
                dot.style.background = '#ffffff';
                i++;
                if (i === 6) {
                    i = 0;
                }
                setTimeout(carouselM, 5000);
            }
        }

        $('.barM').on('click', function (e) {
            i = e.target.id - 1;
            let slide = document.getElementsByClassName('sM1');
            slide[0].style.marginLeft = -i * (100 / 6) + '%';
        });

        function calcVH() {
            var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
            document.querySelector('.mobile').setAttribute('style', 'height:' + vH + 'px;');
        }

        calcVH();
        window.addEventListener('onorientationchange', calcVH, true);


    }, []);


    return (
        <>

            <section id="slideShow" className="slideShow ">
                <div className="slidershow middle">

                    <div className="slides">
                        <input type="radio" name="r" id="r1" />
                        <input type="radio" name="r" id="r2" />
                        <input type="radio" name="r" id="r3" />
                        <input type="radio" name="r" id="r4" />
                        <input type="radio" name="r" id="r5" />
                        <input type="radio" name="r" id="r6" />
                        {/* <input type="radio" name="r" id="r7" /> */}

                        <div className="slide s1">
                            <img src="../assets/Website Sliders-01.jpg" style={{ width: '100vw', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                            {/* </div> */}
                        </div>
                        <div className="slide s2">
                            {/* <div className="card"> */}
                            {/* <div className='future'>
                                    <span>لأنك</span>
                                    <span>المستقبل</span>
                                </div> */}
                            <img src="../assets/Website Sliders-02.jpg" style={{ width: '100vw', right: '0vw' }} alt="" />
                            {/* <span className='with-you'>إحنا معك</span> */}
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                            {/* </div> */}
                        </div>
                        <div className="slide s3">
                            <img src="../assets/Website Sliders-03.jpg" style={{ width: '100vw', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        <div className="slide s4">
                            <img src="../assets/Website Sliders-04.jpg" style={{ width: '100vw', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        <div className="slide s5">
                            <img src="../assets/Website Sliders-05.jpg" style={{ width: '100vw', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        <div className="slide s6">
                            <img src="../assets/Website Sliders-06.jpg" style={{ width: '100vw', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        {/* <div className="slide s7">
                            <img className='text' src='../assets/Website Sliders-07.jpg' style={{ width: '100vw', right: '0vw' }} alt="text 7" />
                        </div> */}

                        <div className="navigation">
                            <label htmlFor="r1" className="bar" id="1"></label>
                            <label htmlFor="r2" className="bar" id="2"></label>
                            <label htmlFor="r3" className="bar" id="3"></label>
                            <label htmlFor="r4" className="bar" id="4"></label>
                            <label htmlFor="r5" className="bar" id="5"></label>
                            <label htmlFor="r6" className="bar" id="6"></label>
                            {/* <label htmlFor="r7" className="bar" id="7"></label> */}
                        </div>
                    </div>
                    <a target='_blank' rel="noopener noreferrer" className='chat' href="https://m.me/LTUC.JO"><i className="fa fa-comment"></i></a>
                </div>


                {/* <div className='chat'> */}
                {/* <div className="chat">
                <i className="fa fa-comment icon"></i>
                <ul className="iconList">
                    <li><i class="fab fa-facebook-messenger"></i></li>
                    <li><i class="fab fa-whatsapp"></i></li>
                </ul> */}
                {/* <button className="dropbtn" type="scall">{context.headerData.nav.studentServicesHeader}</button> */}
                {/* <div className="dropdown-content"> */}

                {/* {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://sisportal-100533.campusnexus.cloud/CMCPortal/?wa=wsignin1.0&wtrealm=https%3a%2f%2fsisportal-100533.campusnexus.cloud%2fCMCPortal%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252fCMCPortal%252fsecure%252fstaff%252fstaportal.aspx&wct=2020-05-07T13%3a03%3a17Z">{context.headerData.nav.studentServices.selfServicePortal}</a> */}
                {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://online.ltuc.com/d2l/home">{context.headerData.nav.studentServices.desireToLearn}</a> */}
                {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://employmenthub.ltuc.com">{context.headerData.nav.studentServices.EducationForEmployment}</a> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}

                {/* <a target='_blank' rel="noopener noreferrer"  href="https://m.me/LTUC.JO"className='chat'><img src={mssIcon} alt="Girl in a jacket" width="60" height="60" /></a> */}

            </section>
            <section id="slideShow" className="mobile">
                <div className="slidershow middle">

                    <div className="slides">
                        <input type="radio" name="r" id="rM1" />
                        <input type="radio" name="r" id="rM2" />
                        <input type="radio" name="r" id="rM3" />
                        <input type="radio" name="r" id="rM4" />
                        <input type="radio" name="r" id="rM5" />
                        <input type="radio" name="r" id="rM6" />
                        {/* <input type="radio" name="r" id="rM7" /> */}

                        <div className="slide sM1">
                            <img src="../assets/Reference_1_Website_Slider_414x896.png" style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                            {/* </div> */}
                        </div>
                        <div className="slide sM2">
                            <img src="../assets/Reference_2_Website Slider_414x896.png" style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="" />
                            {/* <span className='with-you'>إحنا معك</span> */}
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                            {/* </div> */}
                        </div>
                        <div className="slide sM3">
                            <img src="../assets/Reference3_Website_Slider_414x896.png" style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        <div className="slide sM4">
                            <img src="../assets/Reference_4_Website_Slider_414x896.png" style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        <div className="slide sM5">
                            <img src="../assets/Reference_5_Website_Slider_414x896.png" style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        <div className="slide sM6">
                            <img src="../assets/Reference_6_Website_Slider_414x896.png" style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="" />
                            <a target='_blank' className="touch" rel="noopener noreferrer" href={context.headerData.nav.contactUs.link}>{context.headerData.nav.contactUs.text}</a>
                        </div>
                        {/* <div className="slide sM7">
                            <img className='text' src='../assets/Reference_7_Website_Slider_414x896.png' style={{ width: '100vw', top: '0vh', right: '0vw' }} alt="text 7" />
                        </div> */}

                    </div>

                    <div className="navigation">
                        <label htmlFor="rM1" className="barM" id="1"></label>
                        <label htmlFor="rM2" className="barM" id="2"></label>
                        <label htmlFor="rM3" className="barM" id="3"></label>
                        <label htmlFor="rM4" className="barM" id="4"></label>
                        <label htmlFor="rM5" className="barM" id="5"></label>
                        <label htmlFor="rM6" className="barM" id="6"></label>
                        {/* <label htmlFor="rM7" className="barM" id="7"></label> */}
                    </div>
                </div>

                {/* <div className='chat'> */}
                {/* <div className="chat">
                <i className="fa fa-comment icon"></i>
                <ul className="iconList">
                    <li><i class="fab fa-facebook-messenger"></i></li>
                    <li><i class="fab fa-whatsapp"></i></li>
                </ul> */}
                {/* <button className="dropbtn" type="scall">{context.headerData.nav.studentServicesHeader}</button> */}
                {/* <div className="dropdown-content"> */}

                {/* {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://sisportal-100533.campusnexus.cloud/CMCPortal/?wa=wsignin1.0&wtrealm=https%3a%2f%2fsisportal-100533.campusnexus.cloud%2fCMCPortal%2f&wctx=rm%3d0%26id%3dpassive%26ru%3d%252fCMCPortal%252fsecure%252fstaff%252fstaportal.aspx&wct=2020-05-07T13%3a03%3a17Z">{context.headerData.nav.studentServices.selfServicePortal}</a> */}
                {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://online.ltuc.com/d2l/home">{context.headerData.nav.studentServices.desireToLearn}</a> */}
                {/* <a className='analytics-button' target='_blank' rel="noopener noreferrer" href="https://employmenthub.ltuc.com">{context.headerData.nav.studentServices.EducationForEmployment}</a> */}
                {/* </div> */}
                {/* </div> */}
                {/* </div> */}

                {/* <a target='_blank' rel="noopener noreferrer"  href="https://m.me/LTUC.JO"className='chat'><img src={mssIcon} alt="Girl in a jacket" width="60" height="60" /></a> */}
                <a target='_blank' rel="noopener noreferrer" className='chat' href="https://m.me/LTUC.JO"><i className="fa fa-comment"></i></a>

            </section>

            <div className="clearFix"></div>
        </>
    );
}

export default Header;